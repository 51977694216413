/* General Styles */
.about-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
  }
  
  /* Hero Section */
  .hero-section {
    background-image: url('../assets/harrier-interior.jpg');
    background-size: cover;
    background-position: center;
    text-align: center;
    color: white;
    padding: 100px 20px;
    position: relative;
  }
  
  .hero-overlay {
    background: rgba(0, 0, 0, 0.6);
    padding: 50px 20px;
    border-radius: 10px;
    display: inline-block;
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .hero-section p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #FFD700; /* Gold button */
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #003366; /* Dark blue hover */
    color: #fff
  }
  
  /* Mission Section */
  .mission-section {
    display: flex;
    align-items: center;
    padding: 50px 20px;
    background-color: #f9f9f9;
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .text-content, .image-content {
    flex: 1;
  }
  
  .image-content img {
    max-width: 100%;
    border-radius: 10px;
  }
  
  /* Choose Us Section */
  .choose-us-section {
    text-align: center;
    padding: 50px 20px;
  }
  
  .choose-us-section h2 {
    color: #003366;
    margin-bottom: 20px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .feature {
    background: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .feature img {
    width: 50px;
    margin-bottom: 15px;
  }
  
  .feature h3 {
    color: #003366;
    margin-bottom: 10px;
  }
  
  /* Story Section */
  .story-section {
    display: flex;
    align-items: center;
    padding: 50px 20px;
    background-color: #fff;
  }
  
  .story-section .container {
    flex-direction: row-reverse;
  }
  
  /* Testimonials Section */
  .testimonials-section {
    background-color: #003366;
    color: white;
    text-align: center;
    padding: 50px 20px;
  }
  
  .testimonials-section h2 {
    margin-bottom: 30px;
  }
  
  .testimonials-carousel {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .testimonial {
    background: #fff;
    color: #333;
    padding: 20px;
    border-radius: 10px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial h4 {
    margin-top: 10px;
    color: #003366;
  }
  
  /* Call to Action Section */
  .cta-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #f1f1f1;
  }
  
  .cta-section h2 {
    color: #003366;
    margin-bottom: 20px;
  }
  
  .cta-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .mission-section, .story-section {
      flex-direction: column;
    }
  
    .container {
      flex-direction: column;
    }
  
    .features-grid {
      grid-template-columns: 1fr;
    }
  }
  