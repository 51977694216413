.contact-page {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.contact-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-form label {
  display: flex;
  flex-direction: column;
}

.contact-form input, .contact-form textarea {
  padding: 8px;
  font-size: 1rem;
  margin-top: 5px;
}

.contact-form button {
  padding: 10px;
  font-size: 1rem;
  background-color: #1c5f01;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.contact-info {
  margin-top: 20px;
}

.social-media {
  margin-top: 20px;
  text-align: center;
}

.social-icons a {
  margin: 0 15px;
  color: #000000; /* Set the color of the icons */
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #0073e6; /* Change color on hover */
}


.form-status {
  margin-top: 10px;
  color: green;
}

.icon-image {
  filter: grayscale(100%); /* Makes the image grayscale */
}

.icon-image:hover {
  filter: grayscale(0%) sepia(100%) hue-rotate(180deg) saturate(200%); /* Adds color shift on hover */
}

.x-icon {
  background-color: aliceblue;
}

.x-icon:hover {
  background-color: #0073e6;
}


/* Medium devices (Tablets, max-width: 768px) */
@media (max-width: 768px) {
.contact-page {
  padding: 15px;
  max-width: 100%; /* Allow the container to use the full width */
}

.contact-container {
  gap: 15px; /* Reduce gaps for smaller screens */
}

.contact-form input, .contact-form textarea {
  font-size: 0.9rem; /* Adjust input font size */
  padding: 6px; /* Reduce padding */
}

.contact-form button {
  font-size: 0.9rem; /* Adjust button font size */
  padding: 8px; /* Adjust button padding */
}

.social-media {
  margin-top: 15px; /* Reduce spacing */
}

.social-icons a {
  margin: 0 10px; /* Reduce spacing between icons */
}
}

/* Small devices (Phones, max-width: 480px) */
@media (max-width: 480px) {
.contact-page {
  padding: 10px;
}

.contact-container {
  gap: 10px; /* Further reduce gaps */
}

.contact-form input, .contact-form textarea {
  font-size: 0.8rem; /* Further reduce input font size */
  padding: 5px; /* Reduce padding for compact layout */
}

.contact-form button {
  font-size: 0.8rem; /* Adjust button font size */
  padding: 6px; /* Compact padding */
}

.social-media {
  margin-top: 10px;
}

.social-icons a {
  margin: 0 8px; /* Compact spacing between icons */
}

.contact-info {
  font-size: 0.9rem; /* Adjust contact info font size */
}

.form-status {
  font-size: 0.8rem; /* Adjust form status message font size */
}
}
