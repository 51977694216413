/* Hero Section */
/* Hero Section */
.hero {
    position: relative;
    width: 100%;
    height: 50vh; /* Adjust based on your preference */
    overflow: hidden;
  }
  
  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire hero section */
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    z-index: 1;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: white;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: white;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

  
  .hero button {
    background-color: #FFD700; /* Gold button */
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero button:hover {
    background-color: #003366; /* Dark blue hover */
    color: #fff
  }
  
  
  /* About Section */
  .about {
    padding: 40px;
    text-align: center;
  }
  
  .about h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .about p {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .about button {
    background-color: #FFD700; /* Gold button */
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .about button:hover {
    background-color: #003366; /* Dark blue hover */
    color: #fff
  }
  
  /* Featured Vehicles Section */
  .featured-vehicles {
    padding: 40px;
    text-align: center;
  }

  .featured-vehicles button {
    background-color: #FFD700;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  .featured-vehicles button:hover {
    background-color: #003366;
    color: #fff; /* Dark blue hover */
  }
  
  .featured-vehicles h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .vehicle-list {
    display: flex;
    justify-content: space-around;
  }
  
  .vehicle-card {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .vehicle-card img {
    width: 100%;
    height: auto;
  }
  
  .vehicle-card h3 {
    margin-top: 10px;
    font-size: 1.2rem;
  }
  
  .vehicle-card p {
    font-size: 1rem;
    color: #003366;
  }
  
  .vehicle-card button {
    background-color: #FFD700;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
  }

  .vehicle-card button:hover {
    background-color: #003366;
    color: #fff; /* Dark blue hover */
  }
  
 /* Why Choose Us Section */
.why-choose-us {
    background-color: #f9f9f9;
    padding: 50px 20px;
    text-align: center;
  }
  
  .why-choose-us h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .benefits {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .benefit {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
  }
  
  .benefit h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #003366; /* Dark blue */
  }
  
  .benefit p {
    font-size: 1rem;
    color: #555;
  }

  
  /* Testimonials Section */
/* Testimonials Section */
.testimonials {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.testimonials h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
  color: #003366;
}

.testimonial-card {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  position: relative;
  width: 600px;
  margin: 0 auto;
}

.testimonial-card p {
  margin: 10px 0;
}

.testimonial-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.testimonial-controls button {
  background-color: #FFD700;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.testimonial-controls button:hover {
  background-color: #003366;
  color: #fff
}

  
  /* Search Section */
.search {
    background-color: #f0f0f0;
    padding: 50px 20px;
    text-align: center;
  }
  
  .search h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .search input[type="text"] {
    width: 50%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    margin-right: 10px;
    outline: none;
  }
  
  .search input[type="text"]:focus {
    border-color: #003366;
  }
  
  .search button {
    background-color: #FFD700;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .search button:hover {
    background-color: #003366;
    color: #fff

  }

  
  /* Contact Info Section */
.contact-info {
    padding: 50px 20px;
    background-color: #003366;
    color: white;
    text-align: center;
  }
  
  .contact-info p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .contact-info button {
    background-color: #FFD700;
    color: rgb(0, 0, 0);
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-info button:hover {
    background-color: #003366;
    color: #fff
  }

/* Responsive Design */

/* Large tablets and smaller screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .vehicle-list,
  .benefits {
    flex-direction: column;
    align-items: center;
  }

  .vehicle-card,
  .benefit {
    width: 90%;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .about h2,
  .featured-vehicles h2,
  .why-choose-us h2 {
    font-size: 1.8rem;
  }

  .search input[type="text"] {
    width: 80%;
    margin-bottom: 10px;
  }
}

/* Mobile screens (max-width: 768px) */
@media (max-width: 768px) {
  .hero {
    height: 40vh; /* Reduce hero height on smaller screens */
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 0.9rem;
  }

  .hero button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .about,
  .featured-vehicles,
  .why-choose-us,
  .testimonials,
  .search,
  .contact-info {
    padding: 20px 10px;
  }

  .vehicle-list,
  .benefits {
    flex-direction: column;
  }

  .vehicle-card,
  .benefit {
    width: 100%;
    margin-bottom: 15px;
  }

  .testimonial-card {
    width: 90%;
  }

  .search input[type="text"] {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Small mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.8rem;
  }

  .hero p {
    font-size: 0.8rem;
  }

  .hero button {
    padding: 5px 10px;
    font-size: 0.7rem;
  }

  .about h2,
  .featured-vehicles h2,
  .why-choose-us h2 {
    font-size: 1.5rem;
  }

  .contact-info p {
    font-size: 1rem;
  }

  .vehicle-card img {
    height: auto;
  }

  .testimonial-card {
    padding: 15px;
  }
}
