.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensures the footer stays at the bottom */
  }
  
  main {
    flex-grow: 1;
    padding: 20px;
    background-color: #FAFAFA; /* Light background for the page content */
  }
  