.vehicle-details-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
  padding: 20px;
}

.vehicle-details-header {
  text-align: center;
  background-color: #003366;
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.vehicle-details-header h1 {
  font-size: 2.5rem;
  margin: 0;
}

.vehicle-details-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 40px;
}

.vehicle-image {
  flex: 1;
  width: 60%;
  overflow: hidden;
  border-radius: 10px;
}

.vehicle-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-info {
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 40%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-info p {
  font-size: 1.8rem;
  margin: 10px 0;
  color: #555;
  padding: 20px;
}

.vehicle-details-page button {
  background-color: #ffd700;
  color: rgb(0, 0, 0);
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.vehicle-details-page button:hover {
  background-color: #003366;
  color: #fff;
}

.vehicle-info .vehicle-specs {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}

.vehicle-info .card {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-info .card h4 {
  color: #003366;
  font-size: 1.3rem;
}

.vehicle-info .card p {
  margin: 10px 0;
  font-size: 1rem;
}

.vehicle-info .card:last-child {
  margin-right: 0;
}

.vehicle-details-footer {
  margin-top: 50px;
  text-align: center;
}

.vehicle-details-footer p {
  color: #003366;
}

.feature-cards {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-top: 40px;
}

.card {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  color: #003366;
  font-size: 1.3rem;
}

.card p {
  margin: 10px 0;
  font-size: 1rem;
}

/* Responsive Adjustments */

/* Medium Screens (max-width: 768px) */
@media (max-width: 768px) {
  .vehicle-details-content {
    flex-direction: column; /* Stack content vertically */
    gap: 15px;
  }

  .vehicle-image {
    width: 100%; /* Make image container full width */
    margin-bottom: 20px; /* Add margin for spacing between image and info */
  }

  .vehicle-info {
    width: 100%; /* Make info section full width */
    text-align: left; /* Align text to the left for readability */
  }

  .vehicle-info p {
    font-size: 1.5rem; /* Slightly reduce font size */
  }

  .vehicle-details-header h1 {
    font-size: 2rem; /* Reduce header font size */
  }

  .vehicle-details-footer p {
    font-size: 1rem; /* Adjust footer font size */
  }

  .vehicle-info .vehicle-specs {
    flex-direction: column; /* Stack specs vertically */
    gap: 10px;
  }

  .feature-cards {
    flex-direction: column; /* Stack feature cards vertically */
  }

  .card {
    margin-bottom: 20px; /* Add margin between cards */
  }
}

/* Small Screens (max-width: 480px) */
@media (max-width: 480px) {
  .vehicle-details-header {
    padding: 15px; /* Reduce padding */
  }

  .vehicle-details-header h1 {
    font-size: 1.8rem; /* Further reduce header font size */
  }

  .vehicle-info p {
    font-size: 1.3rem; /* Smaller text size */
  }

  .vehicle-details-page button {
    padding: 10px 25px; /* Adjust button size */
    font-size: 1rem; /* Smaller font size */
  }

  .vehicle-info .vehicle-specs {
    gap: 5px; /* Reduce gap between specs */
  }

  .vehicle-info .card {
    padding: 15px; /* Reduce padding inside cards */
  }

  .vehicle-info .card h4 {
    font-size: 1.2rem; /* Reduce card title size */
  }

  .vehicle-info .card p {
    font-size: 0.9rem; /* Reduce card text size */
  }

  .feature-cards {
    gap: 15px; /* Reduce gap between cards */
  }

  .feature-cards .card {
    padding: 15px; /* Adjust padding inside feature cards */
  }
}
