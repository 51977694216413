.password-protect-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.password-protect-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.password-protect-container form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.password-protect-container label {
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.password-protect-container input {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.password-protect-container button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.password-protect-container button:hover {
  background-color: #0056b3;
}


/* Admin Page Container */
.admin-page-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.admin-page-title {
  text-align: center;
  color: #333;
  margin-bottom: 40px;
}

/* Form Section Styling */
.vehicle-form {
  background-color: #fff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-form .form-title {
  text-align: center;
  color: #444;
  margin-bottom: 20px;
}

.vehicle-form .form-field {
  margin-bottom: 15px;
}

.vehicle-form label {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  display: block;
}

.vehicle-form input {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.vehicle-form button[type="submit"] {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
}

.vehicle-form button[type="submit"]:hover {
  background-color: #45a049;
}

/* Vehicle List Styling */
.vehicle-list-container {
  margin-top: 40px;
}

.vehicle-list-container ul {
  list-style-type: none;
  padding: 0;
}


.vehicle-list-container .vehicle-list-item {
  background-color: #fff;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-actions {
  display: flex;
  gap: 10px;
}

.vehicle-actions .edit-btn,
.vehicle-actions .delete-btn {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.vehicle-actions .edit-btn {
  background-color: #ff9800;
}

.vehicle-actions .edit-btn:hover {
  background-color: #f57c00;
}

.vehicle-actions .delete-btn {
  background-color: #f44336;
}

.vehicle-actions .delete-btn:hover {
  background-color: #d32f2f;
}
