/* Header Styles */
.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #003366; /* Deep Blue */
  color: white;
  padding: 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow to give depth */
}

.header a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.header h1 {
  font-size: 2rem; /* Large font size for the website name */
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
}

.header nav ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.header nav ul li {
  margin-right: 20px;
}

.header nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.header nav ul li a:hover {
  color: #FFD700; /* Gold color on hover */
}

/* Responsive Design */

/* Tablets and smaller screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .header h1 {
    font-size: 1.8rem; /* Slightly smaller font for the website name */
  }

  .header nav ul li {
    margin-right: 15px;
  }

  .header nav ul li a {
    font-size: 0.9rem;
  }
}

/* Mobile screens (max-width: 768px) */
@media (max-width: 768px) {
  .header {
    flex-wrap: wrap;
    padding: 10px 20px;
  }

  .header h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    width: 100%; /* Makes the header title span full width */
    text-align: center;
  }

  .header nav ul {
    justify-content: center;
    flex-wrap: wrap; /* Wrap navigation links */
  }

  .header nav ul li {
    margin-right: 10px;
  }

  .header nav ul li a {
    font-size: 0.85rem;
  }
}

/* Small mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .header {
    padding: 10px 15px;
  }

  .header h1 {
    font-size: 1.2rem;
  }

  .header nav ul {
    flex-direction: column; /* Stack navigation links */
    align-items: center;
  }

  .header nav ul li {
    margin-right: 0;
    margin-bottom: 5px; /* Adds spacing between stacked items */
  }

  .header nav ul li a {
    font-size: 0.8rem;
  }
}
