.vehicles-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .vehicles-header {
    background-color: #003366;
    color: white;
    padding: 20px 0;
    text-align: center;
    margin-top: 75px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;  /* Makes sure the header stays on top of other content */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  /* Optional: Adds a shadow to separate the header */
  }
  
  .vehicles-header h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .vehicles-content {
    display: flex;
    flex: 1;
    padding: 20px;
    gap: 20px;
    margin-top: 80px;  /* Adjust this to avoid content being hidden behind the header */
  }
  
  
  .filter-sidebar {
    width: 250px;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 20px;  /* Adjust this value to control how far from the top the sidebar sticks */
    height: 100%;  /* Makes the sidebar take full height minus the padding */
    padding-top: 150px;
  }
  
  
  .filter-sidebar h3 {
    color: #003366;
    margin-bottom: 15px;
  }
  
  .filter-sidebar label {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .filter-sidebar select,
  .filter-sidebar input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .filter-sidebar select:focus,
  .filter-sidebar input:focus {
    outline: none;
    border-color: #003366;
    box-shadow: 0 0 5px rgba(0, 51, 102, 0.5);
  }
  
  .vehicle-card button {
    margin-top: 10px;
  }
  
  .vehicles-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 items per row */
    gap: 20px;
    flex: 1;
  }
  
  .vehicle-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
  }
  
  .vehicle-card img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .vehicle-card h3 {
    font-size: 1.2rem;
    margin: 10px 0;
    color: #003366;
  }
  
  .vehicle-card p {
    margin: 10px 0;
    color: #555;
  }
  
  .vehicle-card button {
    background-color: #FFD700;
    color: #000000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .vehicle-card button:hover {
    background-color: #003366;
    color: #fff;
  }
  
  .vehicles-footer {
    background-color: #003366;
    color: white;
    text-align: center;
    padding: 10px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .vehicles-grid {
      grid-template-columns: 1fr; /* 1 item per row on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .filter-sidebar {
      width: 100%; /* Make filter sidebar full-width on mobile */
    }
  }
  
/* Responsive Adjustments */

/* Medium Screens (max-width: 768px) */
@media (max-width: 768px) {
  .vehicles-content {
    flex-direction: column; /* Stack content vertically */
    gap: 15px;
  }

  .filter-sidebar {
    position: relative; /* Remove sticky positioning */
    width: 100%; /* Take full width */
    padding-top: 20px; /* Adjust padding for better spacing */
  }

  .vehicles-grid {
    grid-template-columns: 1fr; /* 1 item per row on smaller screens */
  }

  .vehicles-header h1 {
    font-size: 2rem; /* Slightly smaller header text */
  }
}

/* Small Screens (max-width: 480px) */
@media (max-width: 480px) {
  .vehicles-header {
    padding: 10px 0; /* Reduce padding for smaller screens */
  }

  .vehicles-header h1 {
    font-size: 1.8rem; /* Further reduce header text size */
  }

  .filter-sidebar {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    box-shadow: none; /* Remove shadow for simplicity on mobile */
  }

  .filter-sidebar h3 {
    font-size: 1.2rem; /* Smaller title size */
  }

  .filter-sidebar label {
    font-size: 0.9rem; /* Smaller label font */
  }

  .filter-sidebar select,
  .filter-sidebar input {
    font-size: 0.9rem; /* Adjust input and select font size */
  }

  .vehicles-grid {
    grid-template-columns: 1fr; /* Single column layout for small screens */
    gap: 10px;
  }

  .vehicle-card {
    padding: 15px; /* Reduce padding inside cards */
  }

  .vehicle-card h3 {
    font-size: 1rem; /* Smaller card title font */
  }

  .vehicle-card p {
    font-size: 0.9rem; /* Smaller card description font */
  }

  .vehicle-card button {
    padding: 8px 15px; /* Reduce button size */
    font-size: 0.9rem; /* Smaller button text */
  }

  .vehicles-footer {
    font-size: 0.9rem; /* Smaller footer text */
  }
}
